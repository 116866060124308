@import '../../../../styles/customMediaQueries.css';

.root {
  padding: 48px 0;
  background: linear-gradient(to bottom, var(--marketplaceColor), var(--marketplaceColorDark));
  width: 100%;
  overflow: hidden;
}

.communitySection {
  text-align: center;
}

.communityHeading {
  font-size: 3rem;
  color: #F5F5F5;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 36px;
}

.communityDescription {
  color: #F5F5F5;
  font-size: 1.1rem;
  max-width: 800px;
  line-height: 1.6;
  text-wrap: balance;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 16px;
}

.communityUsers {
  display: flex;
  gap: 30px;
  padding: 20px 0;
  width: fit-content;
  min-height: 220px;
  will-change: transform;
}

.communityUsersContainer {
  overflow: hidden;
  margin: 0 auto;
  max-width: 1540px;
  padding: 0 24px;
}

.userCard {
  flex: 0 0 auto;
  width: 180px;
}

.usersCircles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.avatarContainer {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.avatarWrapper {
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: white;
  padding: 4px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  transition: transform 0.3s ease;
  overflow: hidden;
}

.avatarWrapper:hover {
  transform: translateY(-5px);
}

.avatarInner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.userBio {
  color: #F5F5F5;
  font-size: 0.9rem;
  line-height: 1.4;
  margin: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limita a 2 líneas */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 200px;
  margin: 8px auto;
  opacity: 0.9;
}

.userName {
  text-align: center;
  margin-top: 12px;
  font-size: 16px;
  color: #F5F5F5;
  font-weight: 600;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  padding: 0 8px;
  width: 100%; /* Asegura que tome todo el ancho disponible */
}

.seeAllButton {
  background-color: white;
  color: var(--marketplaceColor) !important;
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  border-radius: 8px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-block;
  margin-top: 30px;
}

.seeAllButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.carouselContainer {
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.userCard {
  flex: 0 0 auto;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatarWrapper {
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: #F5F5F5;
  padding: 2px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  transition: transform 0.3s ease;
  overflow: hidden;
}

.bioOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 50%;
  padding: 20px;
}

.avatarWrapper:hover .bioOverlay {
  opacity: 1;
}

.bioQuote {
  color: #F5F5F5;
  font-size: 0.9rem;
  line-height: 1.4;
  text-align: center;
  font-style: italic;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limita a 3 líneas */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.carouselButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--marketplaceColor);
  font-size: 24px;
  z-index: 2;
}

.prevButton {
  left: 0;
}

.nextButton {
  right: 0;
}

.carouselButton:hover {
  background: white;
  transform: translateY(-50%) scale(1.1);
}

@media (max-width: 768px) {
  .carouselContainer {
    max-width: 100vw;
    padding: 0 20px;
  }

  .communityUsers {
    gap: 20px;
    min-height: 160px;
    width: auto;
  }
  
  .userCard {
    width: 180px;
  }
  
  .avatarWrapper {
    width: 180px;
    height: 180px;
  }
  
  .userBio {
    font-size: 0.8rem;
    max-width: 160px;
    margin: 6px auto;
  }
  
  .userName {
    font-size: 14px;
  }
  
  .bioQuote {
    font-size: 0.75rem;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .carouselContainer {
    padding: 0 10px;
  }

  .communityUsers {
    gap: 12px;
    min-height: 140px;
  }
  
  .userCard {
    width: 140px;
  }
  
  .avatarWrapper {
    width: 140px;
    height: 140px;
  }
  
  .userBio {
    font-size: 0.75rem;
    max-width: 140px;
    margin: 4px auto;
  }
  
  .carouselButton {
    width: 32px;
    height: 32px;
    font-size: 20px;
  }
  
  .userName {
    font-size: 12px;
  }
  
  .bioQuote {
    font-size: 10px;
    padding: 5px;
  }

  .communityHeading {
    margin-top: 24px;
  }
} 

.listYourStudio {
  background-color: #F5F5F5;
  color: var(--marketplaceColor) !important;
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  border-radius: 8px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-block;
}

.listYourStudio:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

@media (max-width: 480px) {
  .listYourStudio {
    margin-bottom: 24px;
  }
}