.root {
  background: var(--marketplaceColorDark);
  padding: 64px 24px 80px;
}

.ctaSection {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ctaCard {
  background: var(--marketplaceColorDark);
  padding: 48px;
  border-radius: 12px;
  border: 2px dashed #f5f5f5;
  text-align: center;
  max-width: 1000px;
  width: 100%;
  border-radius: 100px 100px 0px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 24px;
}

.micWink {
  width: 80px;
  height: 80px;
  position: relative;
  right: 0.5%;
  top: 195px;
}

@media (max-width: 768px) {
  .micWink {
    right: 0;
    width: 40px;
    height: 40px;
    top: 136px;
  }
}

@media (max-width: 480px) {
  .micWink {
    right: 0;
    width: 40px;
    height: 40px;
    top: 144px;
  }
}

.ctaHeading {
  font-size: 2.5rem;
  color: #F5F5F5;
  margin-bottom: 24px;
  margin-top: 0;
  text-align: center;
}

.ctaPromo {
  font-size: 1.25rem;
  color: #F5F5F5;
  margin-bottom: 32px;
  line-height: 1.5;
  text-align: center;
}

.promoCode {
  font-weight: bold;
  border: 2px dashed #f5f5f5;
  color: #F5F5F5;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.promoCode:hover {
  background: rgba(255, 255, 255, 0.25);
}

.promoCode::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  padding: 6px 12px;
  background: #F5F5F5;
  color: var(--marketplaceColor);
  font-size: 0.8rem;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
}

.promoCode::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 6px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
  margin-bottom: -4px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
}

.promoCode:hover::before,
.promoCode:hover::after {
  opacity: 1;
  visibility: visible;
}

/* Estilo para cuando está enfocado (accesibilidad) */
.promoCode:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

.ctaButton {
  display: inline-block;
  background-color: #F5F5F5;
  color: var(--marketplaceColorDark);
  padding: 16px 32px;
  border-radius: 8px;
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: none;
  transition: transform 0.2s ease;
}

.ctaButton:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .root {
    padding: 48px 16px;
  }

  .ctaCard {
    margin-top: 24px;
    padding: 32px 24px;
  }

  .ctaHeading {
    font-size: 1.625rem;
    margin-bottom: 12px;
  }

  .ctaPromo {
    font-size: 1.125rem;
  }
} 