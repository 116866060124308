.root {
  width: 100%;
  background: var(--marketplaceColorDark);
  overflow: hidden;
  padding: 48px 24px;
}

.testimonialSection {
  text-align: center;
  max-width: 1056px;
  margin: 0 auto;
}

.testimonialHeading {
  font-size: 3rem;
  margin-top: 36px;
  margin-bottom: 16px;
  color: #f5f5f5;
  text-wrap: balance;
  line-height: 1.35;
}

.testimonialDescription {
    color: #F5F5F5;
    font-size: 1.1rem;
    max-width: 800px;
    line-height: 1.6;
    text-wrap: balance;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 32px;
}

.testimonialCarousel {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
}

.testimonialCard {
  position: absolute;
  width: 100%;
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.5s ease;
  display: none;
  background: 
    radial-gradient(circle at 50% 50%, #f5f5f5, #ABCAEE),
    url('../../../../assets/noise.png');
  background-blend-mode: overlay;
  border-radius: 16px;
  padding: 24px;
}

.testimonialCard.active {
  opacity: 1;
  transform: translateX(0);
  position: relative;
  display: block;
}

.rating {
  margin-top: 16px;
  color: var(--marketplaceColor);
  font-size: 24px;
  margin-bottom: 16px;
}

.testimonialText {
  font-size: 1.4rem;
  line-height: 1.6;
  color: var(--colorDark);
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding: 0 20px;
}

.testimonialAuthor {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: rgb(87, 86, 86);
  margin-bottom: 12px;
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
  gap: 32px;
}

.arrows {
  display: flex;
  gap: 16px;
}

.arrow {
  background: none;
  border: 2px solid rgba(255, 255, 255, 0.3);
  color: #F5F5F5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;
  margin-bottom: 16px;
}

.arrow:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: #F5F5F5;
}

.indicators {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 12px;
}

.indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;
}

.indicator.activeIndicator {
  background-color: #F5F5F5;
  transform: scale(1.2);
}

.arrow svg {
  width: 20px;
  height: 20px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .testimonialCarousel {
    position: relative;
    overflow: hidden;
    padding: 0;
    max-width: calc(100% - 32px);
    margin: 0 auto;
  }

  .testimonialCard {
    position: absolute;
    width: 100%;
    transform: translateX(100%);
    opacity: 0;
    display: none;
    /* padding: 20px 16px; */
  }

  .testimonialCard.active {
    position: relative;
    transform: translateX(0);
    opacity: 1;
    display: block;
  }

  .testimonialText {
    font-size: 24px;
    padding: 0 8px;
  }

  .rating {
    font-size: 20px;
  }

  .arrow {
    width: 36px;
    height: 36px;
  }
  
  .arrow svg {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 480px) {

  .testimonialHeading {
    margin-top: 12px;
    margin-bottom: 30px;
  }

  /* .testimonialCard {
    padding: 16px 12px;
  } */

  .testimonialText {
    font-size: 20px;
    line-height: 1.5;
    padding: 0 4px;
  }

  .indicators {
    margin-bottom: 30px
  }

  .indicator {
    width: 8px;
    height: 8px;
    min-width: 8px;
  }

  .navigation {
    gap: 16px;
  }
  
  .arrow {
    width: 32px;
    height: 32px;
  }
}
