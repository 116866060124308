.root {
    padding: 48px 24px 60px;
    background: linear-gradient(to top, #1345CA, #ABCAEE, #ABCAEE);
    /* background: radial-gradient(circle, #F5F5F5, #1345CA); */
    color: #1345CA;
}

.heroContent {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.title {
    font-size: 6rem;
    line-height: 0.9;
    margin-bottom: 24px;
    position: relative;
    z-index: 3;
    text-wrap: pretty;
}

.studioTypeHeading {
    font-size: 2rem;
    margin-bottom: 25px;
    position: relative;
    z-index: 3;
}

.animatedText {
    color: #F5F5F5;
    background: #1345CA;
    padding: 4px 8px;
    border-radius: 8px;
    display: inline-block;
    transition: all 0.3s ease;
}

.listingsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 24px;
}

@media (max-width: 1200px) {
    .listingsGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
}

@media (max-width: 768px) {
    .listingsGrid {
        grid-template-columns: 1fr;
        gap: 16px;
        padding: 0 16px;
    }
}

.listingCard {
    text-decoration: none !important;
    color: inherit;
    background: #F5F5F5;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.listingCard:hover {
    transform: translateY(-4px);
}

.imageWrapper {
    position: relative;
    padding-top: 66.67%;
    /* 3:2 aspect ratio */
}

.listingImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pillsContainer {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    gap: 8px;
    align-items: center;
}

.pricePill {
    background-color: #F5F5F5;
    color: var(--marketplaceColor);
    padding: 4px 12px;
    border-radius: 100px;
    border: 1px solid var(--marketplaceColor);
    font-size: 0.85rem;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    white-space: nowrap;
}

.listingInfo {
    padding: 1rem;
    background: #F5F5F5;
}

.listingMainInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.listingTitle {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--marketplaceColor);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0;
}

.listingLocation {
    font-size: 0.9rem;
    color: #333;
    margin: 0 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0;
}

.locationPill {
    background-color: #f5f5f5;
    color: #666;
    padding: 4px 12px;
    border-radius: 100px;
    font-size: 0.85rem;
    white-space: nowrap;
    flex-shrink: 0;
}

.featuredBadge {
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: var(--marketplaceColor);
    color: #F5F5F5;
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.1px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.listingMeta {
    display: flex;
    gap: 8px;
    align-items: center;
}

.listingsGridContainer {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: visible;
    padding: 0 20px;
    z-index: 1;
}

.topLeftScribble {
    position: absolute;
    top: -50px;
    left: -40px;
    width: 150px;
    height: auto;
    z-index: 0;
    opacity: 0.8;
    pointer-events: none;
    filter: brightness(0) saturate(100%) invert(15%) sepia(71%) 
    saturate(5043%) hue-rotate(218deg) brightness(91%) contrast(96%);
}

.bottomRightScribble {
    position: absolute;
    bottom: -65px;
    right: -40px;
    width: 150px;
    height: auto;
    z-index: 0;
    opacity: 0.8;
    pointer-events: none;
}

.ctaContainer {
    text-align: center;
    margin-top: 48px;
}

.ctaButton {
    display: inline-block;
    padding: 15px 25px;
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--marketplaceColor);
    background: #F5F5F5;
    border-radius: 10px;
    text-decoration: none;
    transition: all 0.3s ease;
}

.ctaButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Loading Spinner */
.loadingPlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: transparent;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.loadingSpinner {
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.loadingText {
    color: #F5F5F5;
    font-size: 1.5rem;
    margin: 0;
    font-weight: 500;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.michaelCoolIconRight {
    position: absolute;
    right: -40px;
    top: 53%;
    width: 100px;
    height: 100px;
    z-index: 1;
    transform: rotate(15deg);
    filter: brightness(0) saturate(100%) invert(15%) sepia(71%) 
            saturate(5043%) hue-rotate(218deg) brightness(91%) contrast(96%);
}

@media (max-width: 1399px) {
    .michaelCoolIconRight {
        width: 100px;
        height: 100px;
        top: 49%;
        right: 100px;
    }
}

@media (max-width: 768px) {
    .title {
        font-size: 4rem;
    }

    .listingsGrid {
        grid-template-columns: 1fr;
        padding: 16px;
        gap: 16px;
    }

    .avatarWrapper {
        width: 90px;
        height: 90px;
    }

    .userName {
        font-size: 12px;
    }

    .topLeftScribble,
    .bottomRightScribble {
        width: 100px;
    }

    .topLeftScribble {
        top: -20px;
        left: -20px;
    }

    .bottomRightScribble {
        bottom: -30px;
        right: -25px;
    }

    .michaelCoolIconLeft,
    .michaelCoolIconRight {
        width: 54px;
        height: 54px;
    }

    .michaelCoolIconLeft {
        left: 10px;
    }

    .michaelCoolIconRight {
        right: 15px;
        top: 55%
    }

}

@media (max-width: 565px) {
    .michaelCoolIconLeft,
    .michaelCoolIconRight {
        width: 54px;
        height: 54px;
    }
}

@media (max-width: 480px) {
    .listingsGrid {
        padding: 12px;
        gap: 12px;
    }

    .topLeftScribble,
    .bottomRightScribble {
        width: 100px;
    }

    .topLeftScribble {
        top: -22px;
        left: -22px;
    }

    .bottomRightScribble {
        bottom: -34px;
        right: -27px;
    }

    .michaelCoolIconLeft,
    .michaelCoolIconRight {
        width: 54px;
        height: 54px;
    }

    .michaelCoolIconLeft {
        left: 5px;
    }

    .michaelCoolIconRight {
        top: 34%;
        right: -1px;
    }
}

@media (max-width: 375px) {
    .michaelCoolIconLeft,
    .michaelCoolIconRight {
        width: 40px;
        height: 40px;
        top: 52%;
    }
    .studioTypeHeading {
        font-size: 1.5rem;
    }
}

@media (min-width: 1200px) {
    .bottomRightScribble {
        filter: brightness(0) saturate(100%) invert(15%) sepia(71%) 
        saturate(5043%) hue-rotate(218deg) brightness(91%) contrast(96%);
    }
}

/* Styles for the See All button */
.seeAllButtonContainer {
    text-align: center;
    margin: 12px 0;
}

@media (max-width: 480px) {
    .seeAllButtonContainer {
        text-align: center;
        margin: 0px;
    }
}

.seeAllButton {
    background-color: #1345CA;
    color: #F5F5F5;
    border: 1.5px solid #F5F5F5;
    padding: 15px 30px;
    font-size: 1.1rem;
    border-radius: 8px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease;
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 24px;
}

.seeAllButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    gap: 24px;
}

.errorTitle {
    color: #F5F5F5;
    font-size: 2rem;
    margin: 0;
}

.errorText {
    color: #F5F5F5;
    font-size: 1.2rem;
    margin: 0;
    opacity: 0.8;
}

.errorButton {
    background-color: #F5F5F5;
    color: var(--marketplaceColor) !important;
    padding: 15px 30px;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
}

.errorButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
