.root {
  padding: 48px 24px 60px;
  background: #1345CA;
  color: #F5F5F5;
}

.explainerSection {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.explainerHeading {
  font-size: 3rem;
  margin-top: 36px;
  margin-bottom: 48px;
  text-wrap: balance;
  line-height: 1.2;
  }

.contentGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  align-items: start;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 24px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.videoContainer {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  border: 2px solid #F5F5F5;
}

.videoWrapperStuvi {
  border: 2px solid #F5F5F5;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.description {
  width: 100%;
  text-align: center;
}

.stuviLogoContainer {
  margin-top: 18px;
  margin-bottom: 8px;
}

.stuviLogo {
  width: 100%;
  max-width: 120px;
  height: auto;
  padding: 1px 0 11px 0;
}

.scribbleLeft {
  width: 100%;
  max-width: 80px;
  rotate: -60deg;
  height: auto;
}

.scribbleRight {
    rotate: 230deg;
  width: 100%;
  max-width: 80px;
  height: auto;
}

.stuviPhotoContainer {
  position: relative;
  width: 85%;
  padding-top: 85%;
  margin: 0 auto;
}

.stuviPhotoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #F5F5F5;
}

.stuviPhoto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.descriptions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 48px;
}

.descriptionTitle {
  font-size: 2rem;
  margin-bottom: 8px;
  color: #F5F5F5;
}

.descriptionText {
  font-size: 1.1rem;
  opacity: 0.9;
}

.ctaContainer {
  margin-top: 48px;
}

.ctaButton {
  display: inline-block;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--marketplaceColor);
  background: #F5F5F5;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.ctaButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1200px) {
  .contentGrid {
    gap: 32px;
  }
}

@media (min-width: 1024px) {
  .scribbleLeft, .scribbleRight {
    position: relative;
    right: -60px;
    top: 70px;
    transform: none;
    left: auto;
  }
}

@media (max-width: 768px) {
  .contentGrid {
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .column {
    gap: 12px;
  }

  .scribbleLeft, .scribbleRight {
    transform: rotate(90deg);
    margin: 0 auto;
    max-width: 60px;
  }

  .videoContainer {
    max-width: 400px;
    margin: 0 auto;
  }

  .description {
    max-width: 400px;
    margin: 0 auto;
  }

  .arrows {
    display: none;
  }
}

@media (max-width: 390px) {
  .contentGrid {
    gap: 0;
  }

  .scribbleLeft, .scribbleRight {
    margin-bottom: 20px;
  }

  .column {
    margin-bottom: 20px;
  }
}

.videoContainerSquare {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Aspect ratio 1:1 para cuadrado */
}

.videoWrapperSquare {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  border: 2px solid #F5F5F5;
}

.videoContainerStuvi {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Mismo aspect ratio que los cuadrados */
}

.videoWrapperStuvi {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #F5F5F5;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
} 

@media (max-width: 480px) {
    .explainerHeading {
        margin-top: 24px;
    }
}