.dividerContainer {
  position: relative;
  height: 0;
  z-index: 2;
}

.dividerImage {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  max-height: 160px;
  margin-top: -48px;
} 

@media (max-width: 768px) {
    .dividerImage {
      margin-top: -38px;
      max-height: 80px;
    }
  }

@media (max-width: 480px) {
  .dividerImage {
    margin-top: -38px;
  }
}